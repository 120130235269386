@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #4A5568;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2D3748;
}

::-webkit-scrollbar-track {
  background-color: #2D3748;
}

.sidebar-active {
  background-color: #4A5568;
  color: #FFFFFF;
}

.sidebar-active:hover {
  background-color: #2D3748;
}

.rotate-180 {
  transform: rotate(180deg);
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3b82f6;
}

input {
  border-style: none;
  outline: none;
  box-shadow: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
  box-shadow: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: none;
}

.animate-bounce:hover {
  animation: bounce 1s ease;
}

input:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
  transition: box-shadow 0.2s;
}
